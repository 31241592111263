import Model from '@/Models/Model'

export default class User extends Model {
  /**
   * Resource
   *
   * @returns {string}
   */
  resource() {
    return 'users'
  }

  /**
   * Get full name
   *
   * @returns {string}
   */
  get fullName() {
    return `${this.name}`
  }

  /**
   * Get initials
   *
   * @returns {string}
   */
  get initials() {
    return `${this.name.charAt(0)}`
  }
}
