import _remove from 'lodash/remove'
import Category from '@/Models/Category'

const getDefaultState = () => ({
  categories: {
    data: [],
    current_page: 1,
    total: 0,
    last_page: 0,
  },
  loading: false,
})

const state = getDefaultState()

const actions = {

  async fetchCategories({ commit }, page) {
    try {
      commit('setLoading', true)
      const response = await Category.params({ page }).get()
      commit('setLoading', false)
      commit('setCategories', response)

      return response
    } catch (error) {
      commit('setLoading', false)

      return error
    }
  },

  async storeCategory({ dispatch }, payload) {
    const category = new Category(payload)
    const response = await category.save()
    dispatch('fetchCategories', state.categories.current_page)

    return new Category(response)
  },

  async updateCategory({ dispatch }, payload) {
    const category = new Category(payload)
    const response = await category.save()
    dispatch('fetchCategories', state.categories.current_page)

    return response
  },

  async destroyCategory({ dispatch, commit }, categoryId) {
    const category = new Category({ id: categoryId })
    const response = await category.delete()
    commit('deleteCategory', categoryId)

    commit('setCurrentPage', state.categories.data.length ? state.categories.current_page : state.categories.current_page - 1)

    if (state.categories.data.length) {
      dispatch('fetchCategories', state.categories.current_page)
    }

    return response
  },
}

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setCategories(state, data) {
    state.categories = data
  },

  setCurrentPage(state, page) {
    state.categories.current_page = page
  },

  setLoading(state, value) {
    state.loading = value
  },

  deleteCategory(state, categoryId) {
    _remove(state.categories.data, { id: categoryId })
  },
}

const getters = {
  getCategories: state => state.categories.data,
  getTotal: state => state.categories.total,
  getLastPage: state => state.categories.last_page,
  getCurrentPage: state => state.categories.current_page,
  getLoading: state => state.loading,
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
