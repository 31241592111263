import {
  mdiNotebookCheckOutline,
  mdiNotebookOutline, mdiNotebookPlusOutline,
} from '@mdi/js'
import RolesEnum from '@/constants/RolesEnum'

export default [
  {
    subheader: 'Procurements',
  },
  {
    title: 'New request',
    icon: mdiNotebookPlusOutline,
    to: 'procurements.newRequest',
  },
  {
    title: 'Requests overview',
    icon: mdiNotebookOutline,
    to: 'procurements.requests.index',
  },
  {
    title: 'Purchase confirmation',
    icon: mdiNotebookCheckOutline,
    to: 'procurements.purchaseConfirmation.index',
    roles: [RolesEnum.OWNER, RolesEnum.ADMIN],
    permission: 'user/canConfirmPurchase',
  },
]
