import Vue from 'vue'
import _remove from 'lodash/remove'
import axios from 'axios'
import User from '@/Models/User'
import userManagementFilters from '@/views/UserManagement/Users/Data/userManagementFilters'

const getDefaultState = () => ({
  users: {
    data: [],
    current_page: 1,
    total: 0,
    last_page: 0,
  },
  filters: userManagementFilters,
  loading: false,
})

const state = getDefaultState()

const actions = {
  async fetchUsers({ commit }, page) {
    try {
      commit('setLoading', true)
      const isOwner = Vue.prototype.$acl.isOwner()
      const user = User.custom(`user-management/${isOwner ? 'owner' : 'admin'}/users`)
        .params({ page })

      if (isOwner && state.filters.role) {
        user.where('roles.name', state.filters.role)
      }

      const response = await user.get()

      commit('setLoading', false)
      commit('setUsers', response)

      return response
    } catch (error) {
      commit('setLoading', false)

      return error
    }
  },

  filterUsers({ commit, dispatch }, filters) {
    commit('setCurrentPage', 1)
    commit('setFilters', filters)
    dispatch('fetchUsers', state.users.current_page)
  },

  async destroyUser({ commit, dispatch }, userId) {
    try {
      const response = await axios.delete(`user-management/${userId}`)
      commit('deleteUser', userId)

      commit('setCurrentPage', state.users.data.length ? state.users.current_page : state.users.current_page - 1)

      if (state.users.data.length) {
        dispatch('fetchUsers', state.users.current_page)
      }

      return response
    } catch (error) {
      return error
    }
  },
}

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setUsers(state, data) {
    state.users = data
  },

  setLoading(state, value) {
    state.loading = value
  },

  setCurrentPage(state, page) {
    state.users.current_page = page
  },

  setFilters(state, filters) {
    state.filters = filters
  },

  deleteUser(state, userId) {
    _remove(state.users.data, { id: userId })
  },
}

const getters = {
  getUsers: state => state.users.data,
  getLoading: state => state.loading,
  getCurrentPage: state => state.users.current_page,
  getLastPage: state => state.users.last_page,
  getFilters: state => state.filters,
}

export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
}
