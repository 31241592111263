<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <transition
      :name="appRouteTransition"
      mode="out-in"
      appear
    >
      <router-view />
    </transition>

    <!-- Dialog Welcome Instructions -->
    <dialog-welcome-instructions
      v-if="isLoggedIn()"
      v-model="dialogInstructionVisible"
      @close="setDialogState({dialogName:'welcomeInstructionsVisible', value: false })"
    />
    <!-- Dialog Welcome Instructions -->
  </component>
</template>

<script>
import { computed } from '@vue/composition-api'
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'
import { mapGetters, mapMutations } from 'vuex'

// Dynamic vh
import useDynamicVh from '@core/utils/useDynamicVh'
import { isLoggedIn } from '@/helpers/authHelper'

// Layouts
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'

// Dialogs
import DialogWelcomeInstructions from '@/components/common/Dialogs/DialogWelcomeInstructions.vue'

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
    DialogWelcomeInstructions,
  },

  data() {
    return {
      visible: true,
    }
  },

  computed: {
    ...mapGetters('ui', ['getDialogVisible']),

    dialogInstructionVisible() {
      return this.getDialogVisible('welcomeInstructionsVisible')
    },
  },

  methods: {
    ...mapMutations('ui', ['setDialogState']),
    isLoggedIn,
  },

  setup() {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },
}
</script>
