import {
  mdiCash,
} from '@mdi/js'
import RolesEnum from '@/constants/RolesEnum'

export default [
  {
    subheader: 'Budgets',
    roles: [RolesEnum.OWNER, RolesEnum.ADMIN],
  },
  {
    title: 'Budgets overview',
    icon: mdiCash,
    to: 'budgets.index',
    roles: [RolesEnum.OWNER, RolesEnum.ADMIN],
  },
]
