import { i18n } from '@/plugins/i18n'
import RolesEnum from '@/constants/RolesEnum'

export default [
  {
    path: '/user-management/users',
    name: 'userManagement.users.index',
    component: () => import('@/views/UserManagement/Users/Pages/UsersIndexPage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: i18n.t('All users'),
      roles: [RolesEnum.OWNER, RolesEnum.ADMIN],
    },
  },
  {
    path: '/user-management/create',
    name: 'userManagement.users.create',
    component: () => import('@/views/UserManagement/Users/Pages/UsersCreatePage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: i18n.t('All users'),
      roles: [RolesEnum.OWNER, RolesEnum.ADMIN],
    },
  },
]
