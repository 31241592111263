import { render, staticRenderFns } from "./VerticalNavMenuHeader.vue?vue&type=template&id=205d5be6&scoped=true&"
import script from "./VerticalNavMenuHeader.vue?vue&type=script&lang=js&"
export * from "./VerticalNavMenuHeader.vue?vue&type=script&lang=js&"
import style0 from "./VerticalNavMenuHeader.vue?vue&type=style&index=0&id=205d5be6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "205d5be6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VIcon,VImg,VSlideXTransition})
