import _head from 'lodash/head'
import _concat from 'lodash/concat'
import RolesEnum from '@/constants/RolesEnum'

export default class PermissionManager {
  constructor(user) {
    this.roles = user.roles
    this.setPermissions(user)
  }

  get getRole() {
    const roles = this.roles.map(role => role.name)

    return _head(roles)
  }

  get getPermissions() {
    return this.permissions
  }

  hasRole(role) {
    if (typeof role === 'string') {
      return this.getRole === role
    }

    return role.find(role => role === this.getRole) === this.getRole
  }

  hasPermission(permission) {
    return this.getPermissions.includes(permission)
  }

  hasSomePermission(permissions) {
    return permissions.some(permission => this.hasPermission(permission) === true)
  }

  isUser() {
    return this.getRole === RolesEnum.USER
  }

  isAdmin() {
    return this.getRole === RolesEnum.ADMIN
  }

  isOwner() {
    return this.getRole === RolesEnum.OWNER
  }

  setPermissions(user) {
    const permissions = user.permissions.map(permission => permission.name)
    const directPermissions = _head(user.roles).permissions.map(permission => permission.name)

    this.permissions = _concat(permissions, directPermissions)
  }
}
