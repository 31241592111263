import Vue from 'vue'
import axios from 'axios'
import _find from 'lodash/find'
import _sum from 'lodash/sum'
import Purchase from '@/Models/Purchase'
import OffersStatusEnum from '@/constants/OffersStatusEnum'
import PurchaseStatusEnum from '@/constants/PurchaseStatusEnum'

const getDefaultState = () => ({
  purchases: {
    data: [],
    current_page: 1,
    total: 0,
    last_page: 0,
  },
  purchase: {},
  purchaseQuantitySum: {},
  purchaseBudget: {},
  loading: false,
})

const state = getDefaultState()

const actions = {
  async fetchConfirmationPurchases({ commit }, page) {
    try {
      commit('setLoading', true)

      const response = await Purchase.custom('purchase-confirm')
        .params({ page })
        .get()
      commit('setLoading', false)
      commit('setPurchases', response)

      return response
    } catch (error) {
      commit('setLoading', false)

      return error
    }
  },

  async fetchConfirmationPurchase({ commit }, purchaseId) {
    try {
      const response = await Purchase.custom('purchase-confirm').find(purchaseId)
      commit('setPurchase', response)

      return response
    } catch (error) {
      return error
    }
  },

  async cancelPurchase({ commit }, purchaseId) {
    try {
      const response = await axios.put(`purchases/cancelPurchase/${purchaseId}`)
      commit('setPurchaseStatus', { purchaseId, status: PurchaseStatusEnum.CANCELED })

      return response
    } catch (error) {
      return error
    }
  },

  async confirmPurchase({ commit }, { purchaseId, canConfirm }) {
    try {
      const response = await axios.put(`purchases/offers/confirmOffers/${purchaseId}`, { canConfirm })

      commit('setPurchaseStatus', { purchaseId, status: PurchaseStatusEnum.CLOSED })
      commit('acceptPurchase', purchaseId)

      return response
    } catch (error) {
      return error
    }
  },

  async acceptOffer({ commit }, offer) {
    try {
      const response = await axios.put(`purchases/offers/acceptOffer/${offer.id}`, { rfp_id: offer.rfp_id })
      commit('setOfferStatus', { purchaseId: offer.purchase_id, rfpId: offer.rfp_id, status: OffersStatusEnum.ACCEPTED })

      return response
    } catch (error) {
      return error
    }
  },

  async rejectOffer({ commit }, offer) {
    try {
      const response = await axios.put(`purchases/offers/rejectOffer/${offer.id}`, { rfp_id: offer.rfp_id })
      commit('setOfferStatus', { purchaseId: offer.purchase_id, rfpId: offer.rfp_id, status: OffersStatusEnum.REJECTED })

      return response
    } catch (error) {
      return error
    }
  },

  async undoOffer({ commit }, offer) {
    try {
      const response = await axios.put(`purchases/offers/undoAcceptedOffer/${offer.id}`, { rfp_id: offer.rfp_id })
      commit('setOfferStatus', { purchaseId: offer.purchase_id, rfpId: offer.rfp_id, status: OffersStatusEnum.OFFER_SENT })

      return response
    } catch (error) {
      return error
    }
  },

  async fetchPurchaseQuantitySum({ commit }, purchaseId) {
    try {
      const response = await axios.get(`purchases/offers/sumQuantity/${purchaseId}`)
      commit('setPurchaseQuantitySum', { purchaseId, value: response.data.value })

      return response
    } catch (error) {
      return error
    }
  },

  async fetchPurchaseBudget({ commit }, { categoryId, purchaseId }) {
    try {
      const response = await axios.get(`budgets/${categoryId}?purchase_id=${purchaseId}`)
      commit('setPurchaseBudget', { purchaseId, data: response.data.data })

      return response
    } catch (error) {
      return error
    }
  },
}

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setPurchases(state, data) {
    state.purchases = data
  },

  setPurchase(state, data) {
    Vue.set(state.purchase, data.id, data)
  },

  setPurchaseStatus(state, { purchaseId, status }) {
    state.purchase[purchaseId].status = status
  },

  setLoading(state, value) {
    state.loading = value
  },

  setCurrentPage(state, page) {
    state.purchases.current_page = page
  },

  setOfferStatus(state, { purchaseId, rfpId, status }) {
    const { rfps } = state.purchase[purchaseId]
    const rfp = _find(rfps, ['id', rfpId])
    rfp.status = status
  },

  setPurchaseQuantitySum(state, { purchaseId, value }) {
    Vue.set(state.purchaseQuantitySum, purchaseId, value)
  },

  setPurchaseBudget(state, { purchaseId, data }) {
    Vue.set(state.purchaseBudget, purchaseId, data)
  },

  acceptPurchase(state, purchaseId) {
    const { rfps } = state.purchase[purchaseId]
    const acceptedOffers = rfps.filter(rfp => rfp.status === OffersStatusEnum.ACCEPTED)
    const otherOffers = rfps.filter(rfp => rfp.status !== OffersStatusEnum.ACCEPTED && rfp.status !== OffersStatusEnum.PENDING && rfp.status !== OffersStatusEnum.LINK_OPENED)

    // eslint-disable-next-line no-param-reassign
    acceptedOffers.forEach(offer => { offer.status = OffersStatusEnum.CONFIRMED })

    if (otherOffers.length) {
      // eslint-disable-next-line no-param-reassign
      otherOffers.forEach(offer => { offer.status = OffersStatusEnum.REJECTED })
    }
  },

}

const getters = {
  getPurchases: state => state.purchases.data,
  getPurchaseById: state => id => state.purchase[id] || null,
  getTotal: state => state.purchases.total,
  getLastPage: state => state.purchases.last_page,
  getCurrentPage: state => state.purchases.current_page,
  getLoading: state => state.loading,
  isPurchaseClosed: state => id => state.purchase[id].status === PurchaseStatusEnum.CLOSED,
  isPurchaseHasQuantity: state => id => {
    if (typeof state.purchase === 'undefined') return false

    return !!state.purchase[id].quantity
  },
  hasOneOrMoreAcceptedOffers: state => id => {
    const { rfps } = state.purchase[id]
    if (typeof rfps === 'undefined') return false

    return rfps.filter(rfp => rfp.offer !== null)
      .some(offer => offer.status === OffersStatusEnum.ACCEPTED)
  },
  getPurchaseQuantitySum: state => id => state.purchaseQuantitySum[id] || null,
  getPurchaseBudget: state => id => state.purchaseBudget[id] || null,
  getSumAcceptedOffers: state => purchaseId => {
    const { rfps } = state.purchase[purchaseId]
    const acceptedRfps = rfps.filter(rfp => rfp.status === OffersStatusEnum.ACCEPTED)
      .map(rfp => rfp.offer.price)

    if (!acceptedRfps.length) return 0

    return _sum(acceptedRfps)
  },
}

export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
}
