import en from 'vee-validate/dist/locale/en.json'

export default {
  'Welcome back!': 'Welcome back!',
  appDescription: 'Jednostavna komunikacija sa sopstvenim dobavljačima, slanje zahteva za ponudom, jasan pregled svih pristiglih ponuda, odabir najbolje, analitika, formiranje izveštaja. Sve na jednom mestu.',
  'Forgot Password?': 'Forgot Password?',
  'Do not have an account?': 'Do not have an account?',
  'Create an account': 'Create an account',
  'Make an account': 'Make an account',
  'Already have an account?': 'Already have an account?',
  'Sign in instead': 'Sign in instead',
  'I agree to': 'I agree to',
  'Remember Me': 'Remember Me',
  Login: 'Login',
  Logout: 'Logout',
  Home: 'Home',
  Add: 'Add',
  Cancel: 'Cancel',
  Delete: 'Delete',
  Edit: 'Edit',
  View: 'View',
  Save: 'Save',
  Send: 'Send',
  Profile: 'Profile',
  Confirm: 'Confirm',
  Description: 'Description',
  Category: 'Category',
  Company: 'Company',
  Files: 'Add files',
  PIB: 'PIB',
  Accept: 'Accept',
  Reject: 'Reject',
  'Deadline for response': 'Deadline for response',
  'Delivery deadline': 'Delivery deadline',
  'Select status': 'Select status',
  'Select category': 'Select category',
  'Delivery date': 'Delivery date',
  Back: 'Back',
  'No data available': 'No data available',
  'Terms of Condition': 'Terms of Condition',
  Close: 'Close',
  Yes: 'Yes',
  No: 'No',

  purchaseStatuses: {
    Active: 'Active',
    Valuation: 'Valuation',
    Closed: 'Closed',
    Canceled: 'Canceled',
  },
  Active: 'Active',
  Closed: 'Closed',
  'Cancel request': 'Cancel request',

  Suppliers: 'Suppliers',
  Supplier: 'Supplier',
  Categories: 'Categoris',
  'Categories overview': 'Categories overview',
  'All suppliers': 'All suppliers',

  SuppliersEmptyText: '',
  Procurements: 'Procurements',
  Request: 'Request',
  Offers: 'Offers',
  Offer: 'Offer',
  'New request': 'New request',
  'Requests overview': 'Requests overview',
  'Closed requests': 'Closed requests',
  'Confirmed requests': 'Confirmed requests',
  'Privacy policy': 'Privacy policy',
  'Format exm.': 'Format exm. +381652013555',

  successRegistrationMessage: 'Uspešno ste obavili registraciju. Proverite Vaš email poslali smo vam link za potvrdu registracije.',

  'New category': 'New category',
  'Add new category': 'Add new category',
  'Edit category': 'Edit Category',
  'Confirm deletion': 'Confirm deletion',
  confirmDeletionQuestion: 'Are you sure you want to delete it? You can’t undo this.',
  'Yes, delete it': 'Yes, delete it',
  'No, just kidding': 'No, just kidding',
  'New supplier': 'New supplier',
  'Add new supplier': 'Add new supplier',
  'Edit supplier': 'Edit supplier',
  'New contact person': 'New contact person',
  'Save contact person': 'Save Contact person',
  'Update contact person': 'Update Contact person',
  Price: 'Price',
  'Offer Pending': 'Pending',
  'Offer Link opened': 'Link opened',
  'Offer Offer sent': 'Sent',
  'Offer Accepted': 'Accepted',
  'Offer Confirmed': 'Confirmed',
  'Offer Rejected': 'Rejected',
  'Purchase Active': 'Active',
  'Purchase Valuation': 'Valuation',
  'Purchase Closed': 'Closed',
  'Purchase Canceled': 'Canceled',
  'Purchase Active Home': 'Active requests',
  'Purchase Valuation Home': 'Valuation requests',
  'Purchase Closed Home': 'Closed requests',
  'Purchase Canceled Home': 'Canceled requests',
  'Bid value Home': 'Bid value',
  'Accepted offers Home': 'Accepted offers',
  'Active suppliers Home': 'Active suppliers',
  Note: 'Note',
  Quantity: 'Quantity',
  Accepted: 'Accepted',
  of: 'of',
  Undo: 'Undo',
  'Confirm request': 'Confirm request',
  'Request is closed': 'Request is closed',
  'Request is canceled': 'Request is canceled',
  'Request sent for verification': 'Request sent for verification',
  'By month': 'By month',
  'By week': 'By week',
  'By category': 'By category',
  'Number of purchases by month': 'Number of purchases by month',
  'Number of purchases by week': 'Number of purchases by week',
  'Number of purchases by category': 'Number of purchases by category',
  'Number of purchases by supplier': 'Number of purchases by supplier',
  'Consumption by month': 'Consumption by month',
  'Consumption by week': 'Consumption by week',
  'Minimum and maximum value by category': 'Value by category (min-max)',
  'Minimum and maximum value by month': 'Minimum and maximum value by month',
  'Total consumption by category': 'Total consumption by category',
  'Chart type': 'Chart type',
  'View type': 'View type',
  'Minimum and maximum value for purchase': 'Minimum and maximum value for purchase',
  'Procurement number': 'Procurement number',
  'Choose number of procurement': 'Choose number of procurement',
  'Add supplier': 'Add supplier',
  'View suppliers': 'View suppliers',
  'There are no suppliers in this category': 'There are no suppliers in this category',
  'View categories': 'View categories',
  'User management': 'User management',
  Users: 'Users',
  'All users': 'All users',
  'Are you confirming the deletion of the user': 'Are you confirming the deletion of the user',
  'Edit user': 'Edit user',
  'Can confirm purchase': 'Can confirm purchase',
  'Select role': 'Select role',
  'Are you sure you want to confirm the request': 'Are you sure you want to confirm the request',
  'Are you sure you want to submit a request for approval': 'Are you sure you want to submit a request for approval',
  'Send for approval': 'Send for approval',
  'Purchase management': 'Procurement management',
  'Purchase confirmation': 'Purchase confirmation',
  'Date from': 'Date from',
  'Date to': 'Date to',
  'Transportation included': 'Transportation included',
  'Transportation not included': 'Transportation not included',
  'Maximum amount to confirm': 'Maximum amount to confirm',
  Budgets: 'Budgets',
  'Budgets overview': 'Budgets overview',
  'Create budget': 'Create budget',
  'Edit budget': 'Edit budget',
  'You have successfully created a budget': 'You have successfully created a budget',
  'Select an existing contact person': 'Select an existing contact person',
  'Add category': 'Add category',
  'Can view the budget': 'Can view the budget',
  'Duplicate request': 'Duplicate request',
  Articles: 'Articles',
  Name: 'Name',
  'Order number': 'Order num.',
  'Unit price': 'Unit price',
  'Quantity requested': 'Quantity requested',
  'Quantity offered': 'Quantity offered',

  columns: {
    id: 'ID',
    request_number: 'REQUEST NO.',
    name: 'NAME',
    actions: 'ACTIONS',
    pib: 'PIB',
    status: 'STATUS',
    category: 'CATEGORY',
    created_at: 'CREATED AT',
    date_for_offer: 'RESPONSE DEADLINE',
    email: 'EMAIL',
    role: 'ROLE',
    budget: 'BUDGET',
    spent: 'SPENT',
    date_from: 'DATUM FROM',
    date_to: 'DATUM TO',
  },

  fields: {
    name: 'Name',
    email: 'Email',
    password: 'Password',
    companyName: 'Company name',
    pib: 'PIB',
    nameAndSurname: 'Name and surname',
    phone: 'Phone',
    passwordConfirm: 'Password confirm',
    privacyPolicy: 'Privacy policy',
    description: 'Description',
    contactPerson: 'Contact person',
    category: 'Category',
    categories: 'Categories',
    file: 'Files',
    price: 'Price',
    date_for_offer: 'Deadline for response',
    date_for_delivery: 'Deadline for delivery',
    request_description: 'Request description',
    quantity: 'Quantity',
    unit: 'Unit',
    city: 'City',
    companyAddress: 'Company address',
    role: 'Role',
    category_id: 'Category',
    budget: 'Budget',
    date_from: 'Date from',
    date_to: 'Date to',
  },
  validation: {
    ...en.messages,
    ...{
      required_checkbox: 'The {_field_} field is required',
      phone_validation: 'The {_field_} must be formatted in the format +381652013555',
    },
  },
}
