import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from '@/store/app'
import user from '@/store/user'
import userManagement from '@/store/user-management'
import categories from '@/store/categories'
import suppliers from '@/store/suppliers'
import purchases from '@/store/purchases'
import statistics from '@/store/statistics'
import ui from '@/store/ui'
import budgets from '@/store/budgets'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    appConfig: appConfigStoreModule,
    app,
    user,
    userManagement,
    categories,
    suppliers,
    purchases,
    statistics,
    ui,
    budgets,
  },

  actions: {
    resetState({ commit }) {
      commit('user/resetState')
      commit('userManagement/resetState')
      commit('categories/resetState')
      commit('suppliers/resetState')
      commit('purchases/resetState')
      commit('purchases/purchaseConfirmation/resetState')
      commit('statistics/resetState')
      commit('ui/resetState')
      commit('budgets/resetState')
    },
  },
})
