import { mdiTagMultipleOutline, mdiTruckOutline } from '@mdi/js'

export default [
  {
    subheader: 'SuppliersEmptyText',
  },
  {
    title: 'Categories overview',
    icon: mdiTagMultipleOutline,
    to: 'categories.index',
  },
  {
    title: 'All suppliers',
    icon: mdiTruckOutline,
    to: 'suppliers.index',
  },
]
