import Model from '@/Models/Model'

export default class Purchase extends Model {
  /**
   * Resource
   *
   * @returns {string}
   */
  resource() {
    return 'purchases'
  }
}
