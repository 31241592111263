import { mdiHomeOutline } from '@mdi/js'

import suppliers from '@/navigation/vertical/suppliers'
import procurements from '@/navigation/vertical/procurements'
import userManagement from '@/navigation/vertical/user-management'
import budgets from '@/navigation/vertical/budgets'

export default [
  {
    title: 'Home',
    icon: mdiHomeOutline,
    to: 'home',
  },

  ...suppliers,
  ...procurements,
  ...userManagement,
  ...budgets,
]
