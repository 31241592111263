import Model from '@/Models/Model'

export default class Supplier extends Model {
  /**
   * Resource
   *
   * @returns {string}
   */
  resource() {
    return 'suppliers'
  }
}
