import Model from '@/Models/Model'

export default class Budget extends Model {
  /**
   * Resource
   *
   * @returns {string}
   */
  resource() {
    return 'budgets'
  }
}
