import { i18n } from '@/plugins/i18n'
import RolesEnum from '@/constants/RolesEnum'

export default [
  {
    path: '/procurements/new-request',
    name: 'procurements.newRequest',
    component: () => import('@/views/Procurements/NewRequest/Pages/NewRequestPage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: i18n.t('New request'),
    },
  },
  {
    path: '/procurements/confirm/:hash',
    name: 'procurements.confirmation',
    component: () => import('@/views/Procurements/Confirmation/Pages/ConfirmationPage.vue'),
    meta: {
      layout: 'blank',
      auth: false,
      guest: true,
      title: i18n.t('New request'),
    },
  },
  {
    path: '/procurements/requests',
    name: 'procurements.requests.index',
    component: () => import('@/views/Procurements/Requests/Pages/RequestsIndexPage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: i18n.t('Requests overview'),
    },
  },
  {
    path: '/procurements/requests/:id',
    name: 'procurements.requests.show',
    component: () => import('@/views/Procurements/Requests/Pages/RequestsShowPage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: 'Request',
    },
  },
  {
    path: '/procurements/purchase-confirmation',
    name: 'procurements.purchaseConfirmation.index',
    component: () => import('@/views/Procurements/PurchaseConfirmation/Pages/PurchaseConfirmationIndexPage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: 'Purchase confirmation',
      roles: [RolesEnum.OWNER, RolesEnum.ADMIN],
      permission: 'user/canConfirmPurchase',
    },
  },
  {
    path: '/procurements/purchase-confirmation/:id',
    name: 'procurements.purchaseConfirmation.show',
    component: () => import('@/views/Procurements/PurchaseConfirmation/Pages/PurchaseConfirmationShowPage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: 'Purchase confirmation',
      roles: [RolesEnum.OWNER, RolesEnum.ADMIN],
      permission: 'user/canConfirmPurchase',
    },
  },
]
