import { i18n } from '@/plugins/i18n'

export default [
  {
    path: '/suppliers',
    name: 'suppliers.index',
    component: () => import('@/views/Suppliers/Pages/SuppliersIndexPage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: i18n.t('All suppliers'),
    },
  },
]
