const getDefaultState = () => ({
  dialogs: {
    welcomeInstructionsVisible: false,
  },
})

const actions = {}

const state = getDefaultState()

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setDialogState(state, { dialogName, value }) {
    state.dialogs[dialogName] = value
  },
}

const getters = {
  getDialogVisible: state => dialog => state.dialogs[dialog],
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
