<template>
  <v-fade-transition mode="out-in">
    <v-icon
      :key="isDark"
      @click="toggle"
    >
      {{ isDark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
    </v-icon>
  </v-fade-transition>
</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'
import useAppConfig from '@core/@app-config/useAppConfig'
import EventBus from '@/plugins/event-bus'

export default {
  setup() {
    const { isDark } = useAppConfig()

    return {
      isDark,

      // Icons
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
    }
  },

  methods: {
    toggle() {
      EventBus.$emit('changeTheme', !this.isDark)
      this.isDark = !this.isDark
    },
  },
}
</script>
