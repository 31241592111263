import Model from '@/Models/Model'

export default class Category extends Model {
  /**
   * Resource
   *
   * @returns {string}
   */
  resource() {
    return 'categories'
  }
}
