import axios from 'axios'
import _remove from 'lodash/remove'
import Budget from '@/Models/Budget'

const getDefaultState = () => ({
  data: {
    data: [],
    current_page: 1,
    total: 0,
    last_page: 0,
  },
  loading: false,
})

const state = getDefaultState()

const actions = {
  async fetchBudgets({ commit }, page) {
    try {
      commit('setLoading', true)
      const response = await Budget.params({ page }).get()
      commit('setLoading', false)
      commit('setBudgets', response)

      return response
    } catch (error) {
      commit('setLoading', false)

      return error
    }
  },

  async storeBudget(context, payload) {
    return axios.post('budgets', payload)
  },

  async updateBudget({ dispatch }, payload) {
    const budget = new Budget(payload)
    const response = await budget.save()
    dispatch('fetchBudgets', state.data.current_page)

    return response
  },

  async destroyBudget({ dispatch, commit }, budgetId) {
    const budget = new Budget({ id: budgetId })
    const response = await budget.delete()
    commit('deleteBudget', budgetId)

    commit('setCurrentPage', state.data.data.length ? state.data.current_page : state.data.current_page - 1)

    if (state.data.data.length) {
      dispatch('fetchBudgets', state.data.current_page)
    }

    return response
  },
}

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setBudgets(state, data) {
    state.data = data
  },

  setCurrentPage(state, page) {
    state.data.current_page = page
  },

  setLoading(state, value) {
    state.loading = value
  },

  deleteBudget(state, budgetId) {
    _remove(state.data.data, { id: budgetId })
  },
}

const getters = {
  getBudgets: state => state.data.data,
  getTotal: state => state.data.total,
  getLastPage: state => state.data.last_page,
  getCurrentPage: state => state.data.current_page,
  getLoading: state => state.loading,
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
