import { i18n } from '@/plugins/i18n'
import RolesEnum from '@/constants/RolesEnum'

export default [
  {
    path: '/budgets/budgets',
    name: 'budgets.index',
    component: () => import('@/views/Budgets/Pages/BudgetsIndexPage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: i18n.t('Budgets overview'),
      roles: [RolesEnum.OWNER, RolesEnum.ADMIN],
    },
  },
  {
    path: '/budgets/create',
    name: 'budgets.create',
    component: () => import('@/views/Budgets/Pages/BudgetsCreatePage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: i18n.t('Create budget'),
      roles: [RolesEnum.OWNER, RolesEnum.ADMIN],
    },
  },
]
