import axios from 'axios'
import Vue from 'vue'
import PackagesEnum from '@/constants/PackagesEnum'

const getDefaultState = () => ({
  currentUser: {
    id: null,
    name: null,
    email: null,
    email_verified_at: null,
    created_at: null,
    updated_at: null,
    company_id: null,
    phone_number: null,
    profile_picture: '',
    purchase_confirmation: 0,
    confirm_limit: 0,
    file_name: '1.png',
    company: {
      id: null,
      name: null,
      pricing: {
        key: PackagesEnum.TRIAL,
        name: 'Trial',
      },
    },
    roles: [],
  },
  ownerRoles: [],
})

const actions = {
  async fetchRolesForOwner({ commit }) {
    try {
      const response = await axios.get('user-management/owner/getRoles')
      commit('setOwnerRoles', response.data)

      return response
    } catch (error) {
      return error
    }
  },
}

const state = getDefaultState()

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setCurrentUser(data, payload) {
    state.currentUser = payload
  },

  setOwnerRoles(state, payload) {
    state.ownerRoles = payload
  },

  setUserAvatar: (state, payload) => {
    state.currentUser.file_name = payload.file_name
  },

  setUserName: (state, payload) => {
    state.currentUser.name = payload.name
  },
}

const getters = {
  getCurrentUser: state => state.currentUser,
  getUserCompanyId: state => state.currentUser.company_id,
  getUserCompanyName: state => state.currentUser.company.name,
  getOwnerRoles: state => state.ownerRoles,
  canConfirmPurchase: state => state.currentUser.purchase_confirmation || Vue.prototype.$acl.isOwner(),
  getConfirmLimit: state => (state.currentUser.confirm_limit ? state.currentUser.confirm_limit : 0),

  isTrial: state => (state.currentUser.company.pricing.key === PackagesEnum.TRIAL),
  isBasic: state => (state.currentUser.company.pricing.key === PackagesEnum.BASIC),
  isBasicPlus: state => (state.currentUser.company.pricing.key === PackagesEnum.BASIC_PLUS),
  isAdvanced: state => (state.currentUser.company.pricing.key === PackagesEnum.ADVANCED),
  isPremium: state => (state.currentUser.company.pricing.key === PackagesEnum.PREMIUM),
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
