import { mdiAccountGroupOutline } from '@mdi/js'
import RolesEnum from '@/constants/RolesEnum'

export default [
  {
    subheader: 'User management',
    roles: [RolesEnum.OWNER, RolesEnum.ADMIN],
  },
  {
    title: 'Users',
    icon: mdiAccountGroupOutline,
    to: 'userManagement.users.index',
    roles: [RolesEnum.OWNER, RolesEnum.ADMIN],
  },
]
