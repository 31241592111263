import axios from 'axios'
import _find from 'lodash/find'

const getDefaultState = () => ({
  homepageCalculations: {
    accepted_offers_count: 0,
    accepted_offers_value: 0,
    active_suppliers_count: 0,
    statuses: [
      { status: 1, ukupno: 0 },
      { status: 2, ukupno: 0 },
      { status: 3, ukupno: 0 },
      { status: 4, ukupno: 0 },
    ],
  },
})

const state = getDefaultState()

const actions = {
  async fetchCountPurchasesByStatus({ commit, rootGetters }) {
    try {
      const response = await axios.get(`statistics/calculations/homepageCalculations?company_id=${rootGetters['user/getUserCompanyId']}`)
      commit('setHomepageCalculations', response.data)

      return response
    } catch (error) {
      return error
    }
  },
}

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setHomepageCalculations(state, data) {
    state.homepageCalculations = data
  },
}

const getters = {
  getHomepageCalculations: state => state.homepageCalculations,
  findPurchaseCountByStatus: state => status => {
    const count = _find(state.homepageCalculations.statuses, ['status', status])

    return count ? count.ukupno : 0
  },
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
